<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addPossession)">
        <section class="mb-2">
          <div>
            <label>Title *</label>
            <validation-provider
              v-slot="validationContext"
              :rules="{ required: true, min: 2 }"
              name="Title"
            >
              <b-form-input
                v-model="possession.title"
                :state="getValidationState(validationContext)"
                class="mb-1"
                placeholder="Enter title"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div class="mb-1">
            <label>Description</label>
            <b-form-textarea v-model="possession.description" placeholder="Enter description"/>
          </div>

          <div class="mb-1">
            <label>Colour</label>
            <b-form-input v-model="possession.colour" placeholder="Enter colour"/>
          </div>

          <div class="mb-1">
            <label>Origin</label>
            <b-form-input v-model="possession.origin" placeholder="Enter origin"/>
          </div>

          <div class="mb-1">
            <label>Cost</label>
            <b-form-input
              v-model="possession.cost_of_item"
              type="number"
              step="0.01"
              placeholder="Enter cost of item"
            />
          </div>

          <div class="mb-1">
            <label>Is Prohibited</label>
            <b-form-select v-model="possession.is_prohibited">
              <b-form-select-option selected value="false">No</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Is Risk</label>
            <b-form-select v-model="possession.is_risk">
              <b-form-select-option selected value="false">No</b-form-select-option>
              <b-form-select-option value="true">Yes</b-form-select-option>
            </b-form-select>
          </div>

          <div class="mb-1">
            <label>Owner</label>
            <CAutoComplete
              :items="owners"
              :get-label="getOwnerLabel"
              :component-item="ownerTemplate"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for an owner..."
              @update-items="searchOwners"
              @input="selectOwner"
            />
          </div>

          <div class="mb-2">
            <label>Category *</label>
            <CAutoComplete
              :items="categories"
              :component-item="nameTemplate"
              :get-label="getCategoryLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a category..."
              @update-items="searchCategories"
              @input="selectCategory"
            />
          </div>

          <div class="mb-1">
            <label>Sub Category *</label>
            <CAutoComplete
              :disabled="!possession.item_category_id"
              :items="subCategories"
              :component-item="nameTemplate"
              :get-label="getCategoryLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a subcategory..."
              @update-items="searchSubCategories"
              @input="selectSubCategory"
            />
          </div>

          <div class="mb-1">
            <label>Location *</label>
            <CAutoComplete
              :items="locations"
              :component-item="nameTemplate"
              :get-label="getLocationLabel"
              :auto-select-one-item="false"
              :input-attrs='{class:"form-control"}'
              :min-len="0"
              :wait="300"
              placeholder="Search for a location..."
              @update-items="searchLocations"
              @input="selectLocation"
            />
          </div>

        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer"/>
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button
            :disabled="!valid()"
            type="submit"
            variant="primary"
          >Add new possession</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import CategoriesService from '@/services/PossessionCategoriesService';
import PossessionsService from "@/services/PossessionsService";
import OwnersService from "@/services/OwnersService";
import LocationsService from "@/services/LocationsService";
import CAutoComplete from "@/components/input/CAutocomplete.vue";
import ItemNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemNameTemplate.vue";
import OwnerTemplate from "@/components/input/CAutoCompleteTemplates/OwnerTemplate.vue";

export default {
  components: {CAutoComplete},
  props: {
    parentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ownerTemplate: OwnerTemplate,
      nameTemplate: ItemNameTemplate,
      categorySearch: '',
      categories: [],
      subcategorySearch: '',
      subCategories: [],
      locationSearch: '',
      locations: [],
      ownerSearch: '',
      owners: [],
      possession: {
        title: '',
        description: '',
        colour: '',
        origin: '',
        is_prohibited: false,
        is_risk: false,
        date_of_purchase: null,
        cost_of_item: null,
        item_category_id: null,
        item_sub_category_id: null,
        location_id: 2,
        owner_uid: null,
      },
    };
  },
  mounted() {
    this.getCategories();
    this.getSubCategories();
    this.getLocations();
    this.getOwners();
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    valid() {
      if(this.possession.title.length <= 0) {
        return false
      }
      if(!this.possession.item_category_id) {
        return false
      }
      if(!this.possession.item_sub_category_id) {
        return false
      }
      if(!this.possession.location_id) {
        return false
      }

      return true
    },
    async addPossession() {
      this.possession.title = this.possession.title.trim();
      this.possession.is_risk = this.possession.is_risk === 'true';
      this.possession.is_prohibited = this.possession.is_prohibited === 'true';

      // Convert cost of item to integer
      this.possession.cost_of_item = parseInt(this.possession.cost_of_item);

      PossessionsService.createPossession(this.possession).then(() => {
        this.$toast.success(`Added possession ${this.possession.title} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not add possession, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    getLocationLabel(loc) {
      if(!loc) {
        return ""
      }

      return loc.name
    },
    searchLocations(input) {
      this.locationSearch = input
      this.getLocations()
    },
    selectLocation(loc) {
      this.possession.location_id = loc ? loc.id : ""
    },
    async getLocations() {
      try {
        const res = await LocationsService.getLocations({
          search: this.locationSearch,
          page_size: 10
        })
        this.locations = res.data.data;
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get locations, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    getCategoryLabel(cat) {
      if(!cat) {
        return ""
      }
      return cat.name
    },
    searchCategories(input) {
      this.categorySearch = input
      this.getCategories()
    },
    selectCategory(category) {
      this.possession.item_category_id = category ? category.id : ""
      this.getSubCategories()
    },
    getCategories() {
      CategoriesService.listCategories({
        search: this.categorySearch,
        parent_id: '0'
      }).then(res => {
        this.categories = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get categories, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    searchSubCategories(input) {
      this.subcategorySearch = input
      this.getSubCategories()
    },
    selectSubCategory(category) {
      this.possession.item_sub_category_id = category ? category.id : ""
    },
    getSubCategories() {
      CategoriesService.listCategories({
        search: this.subcategorySearch,
        parent_id: this.possession.item_category_id
      }).then(res => {
        this.subCategories = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get subcategories, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getOwnerLabel (owner) {
      if (owner === undefined) {
        return ""
      }
      return owner.identifier
    },
    selectOwner(owner) {
      this.possession.owner_uid = owner ? owner.uid : ''
    },
    searchOwners(input) {
      this.ownerSearch = input;
      this.getOwners();
    },
    async getOwners() {
      OwnersService.getOwners({
        search: this.ownerSearch,
        page_size: 10,
      }).then(res => {
        this.owners = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get owners, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
  },
};
</script>
